import request from '@/utils/request';

export function shcemeRecord(params) {
  return request({
    url: '/scheme/record/getList',
    method: 'get',
    params,
  });
}

// 获取充值记录志愿列表
export function getVolunteerList(params) {
  return request({
    url: '/scheme/record/getVolunteerList',
    method: 'get',
    params,
  });
}

// 查询方案exportSchemeExcel
export function getSchemeList(params) {
  return request({
    url: '/scheme/getList',
    method: 'get',
    params,
  });
}

// 导出PDF
export function exportSchemePDF(params) {
  return request({
    url: '/scheme/record/export',
    method: 'get',
    responseType: 'blob',
    params,
  });
}

// 导出excel
export function exportSchemeExcel(params) {
  return request({
    url: '/scheme/record/createExcel',
    method: 'get',
    responseType: 'blob',
    params,
  });
}

// 获取地域列表
export function getRegionList() {
  return request({
    url: '/scheme/getRegionList',
    method: 'get',
  });
}

// 删除方案记录
export function delRecord(params) {
  return request({
    url: '/scheme/record/del',
    method: 'post',
    params,
  });
}

// 新增修改充值记录志愿顺序(设置数字)
export function updateOrder(params) {
  return request({
    url: '/scheme/record/updateOrder',
    method: 'post',
    params,
  });
}

// 上下移动方案记录
export function updSchemeOrder(params) {
  return request({
    url: '/scheme/record/updSchemeOrder',
    method: 'post',
    params,
  });
}

// 修改志愿记录时插入
export function addUserVolunteer(params) {
  return request({
    url: '/scheme/record/addUserVolunteer',
    method: 'post',
    data: params,
  });
}
